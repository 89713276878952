<template>
  <div style="display: grid; gap: 0.5rem">
    <AppLabel :label="label" :label-right="displayDepartureDate" />
    <FlightCardIcons :flight-direction="flightDirection" />
    <FlightCardDetails :flight-direction="flightDirection" :price="price" />
  </div>
</template>

<script>
import { date } from '../../../other/utils/helpers/date'
import AppLabel from '../../AppLabel.vue'
import FlightCardDetails from './FlightCardDetails.vue'
import FlightCardIcons from './FlightCardIcons.vue'

const formatDepartureDate = timestamp =>
  date.formatUTC('-d -ms, -dl', timestamp)

export default {
  name: 'DetailsFlight',
  components: { AppLabel, FlightCardIcons, FlightCardDetails },
  props: {
    label: String,
    flightDirection: Object,
    price: Number,
  },
  computed: {
    displayDepartureDate() {
      const date = this.flightDirection.segments[0].departureDatetime
      return formatDepartureDate(date)
    },
  },
}
</script>

<style scoped lang="scss"></style>
