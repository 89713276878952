<template>
  <div class="container-xs">
    <AppBackHeader to="/search/flights/">FLIGHT DETAILS</AppBackHeader>
    <div class="trip-flight-details">
      <DetailsFlight
        :flight-direction="flight.departureFlight"
        :price="price"
        label="DEPARTURE FLIGHT"
      />
      <DetailsFlight
        v-if="isSelected"
        :flight-direction="flight.returnFlight"
        :price="price"
        label="RETURN FLIGHT"
      />
    </div>
    <XButton
      type="large"
      style="width: 100%; margin-top: 3rem"
      @click="onButtonConfirm"
      >{{ isSelected ? 'CONFIRM' : 'SELECT' }}</XButton
    >
    <InfoModal
      ref="infoModal"
      title="ARE YOU SURE YOU WANT TO MAKE CHANGES?"
      :text="infoModalText"
      cta="CONFIRM"
      alt-cta="CANCEL"
      hide-close-button
      @alt-cta="$refs.infoModal.hide()"
      @cta="onModalConfirm"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AppBackHeader from '../../../components-rf/AppBackHeader.vue'
import InfoModal from '../../../components-rf/modals/InfoModal.vue'
import DetailsFlight from '../../../components-rf/search/flights/DetailsFlight.vue'
import XButton from '../../../components/common/global/XButton.vue'
import { FLIGHT } from '../../../constants/FLIGHT'
import { SEARCH } from '../../../constants/SEARCH'

const FLIGHT_CHANGE_TEXT = 'This change will also affect your returning flight.'
const FLIGHT_HOTEL_CHANGE_TEXT =
  'This change will also affect your returning flight, and might affect your hotel due to changed arrival date.'

export default {
  name: 'TripFlightDetails',
  components: { AppBackHeader, XButton, InfoModal, DetailsFlight },
  data() {
    return { FLIGHT }
  },
  computed: {
    ...mapGetters('SearchState', ['getSelected', 'searchType']),
    selection: vm => vm.getSelected({}),
    previousSelection: vm => vm.getSelected({ confirmed: true }),
    previousFlight: vm => vm.previousSelection.flight,
    flight: vm => vm.selection.flight,
    price: vm => vm.selection.flightPrice / FLIGHT.ONE_WAY_PRICE_DIVIDER,
    infoModalText() {
      const isPackageOrCombo =
        this.searchType === SEARCH.TYPE.COMBO ||
        this.searchType === SEARCH.TYPE.PACKAGE
      const isDifferentArrivalDate =
        this.previousFlight.checkInDate !== this.flight.checkInDate
      const isPotentialHotelChange = isPackageOrCombo && isDifferentArrivalDate
      return isPotentialHotelChange
        ? FLIGHT_HOTEL_CHANGE_TEXT
        : FLIGHT_CHANGE_TEXT
    },
    isSelected() {
      return this.previousFlight.id === this.flight.id
    },
  },
  methods: {
    ...mapActions('SearchState', ['select']),
    onButtonConfirm() {
      if (this.isSelected) this.$router.push('/search/summary')
      else this.$refs.infoModal.show()
    },
    onModalConfirm() {
      this.select({ flightId: this.flight.id, confirm: true })
      this.$refs.infoModal.hide()
    },
  },
}
</script>

<style scoped lang="scss">
.trip-flight-details {
  display: grid;
  gap: 3rem;
  margin-top: 3rem;
  & > div {
    display: grid;
    gap: 0.75rem;
  }
}
</style>
