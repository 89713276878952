<template>
  <div>
    <template v-for="(segment, index) in segments">
      <div class="app-card flight-segment-details">
        <div class="flight-segment-details-icons">
          <IconAirplaneDepart
            style="justify-self: center; align-self: center"
          />
          <div style="width: 1px; height: 100%; background: black"></div>
          <IconAirplaneArrive
            style="justify-self: center; align-self: center"
          />
        </div>
        <div class="flight-segment-details-content">
          <div>
            <p class="flight-segment-details-time">
              {{ formatFlightTime(segment.departureDatetime) }}
              <sup v-if="segment.departureDaysDiff" style="font-size: 12px"
                >+{{ segment.departureDaysDiff }}</sup
              >
            </p>
            <p
              v-if="segment.departureAirport"
              class="flight-segment-details-description"
            >
              <span class="bold">
                ({{ segment.departureAirport.iataCode }})
              </span>
              {{ segment.departureAirport.name }}
            </p>
          </div>
          <div class="flight-segment-details-duration">
            <IconClock />
            <p>{{ formatFlightDuration(segment.duration) }}</p>
          </div>
          <div>
            <p class="flight-segment-details-time">
              {{ formatFlightTime(segment.arrivalDatetime) }}
              <sup v-if="segment.arrivalDaysDiff" style="font-size: 12px"
                >+{{ segment.arrivalDaysDiff }}</sup
              >
            </p>
            <p
              v-if="segment.arrivalAirport"
              class="flight-segment-details-description"
            >
              <span class="bold">
                ({{ segment.arrivalAirport.iataCode }})
              </span>
              {{ segment.arrivalAirport.name }}
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="!isLast(index)"
        class="flight-segment-details"
        style="padding: 0 1rem; height: 60px"
      >
        <AppDashedLine direction="vertical" style="justify-self: center" />
        <div class="flight-segment-details-duration">
          <IconClock />
          <p>{{ formatCxnDuration(index) }} connecting</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { date } from '../../../other/utils/helpers/date'
import IconAirplaneDepart from '../../icons/search/IconAirplaneDepart.vue'
import IconAirplaneArrive from '../../icons/search/IconAirplaneArrive.vue'
import IconClock from '../../icons/common/IconClock.vue'
import AppDashedLine from '../../AppDashedLine.vue'

const formatFlightDuration = timestamp =>
  date.prettyDuration('-hh -min2m')(timestamp)
const formatFlightTime = timestamp =>
  date.formatUTC('-h2:-min2', timestamp, true).toLowerCase()
const parseDays = timestamp => {
  const isoDateTime = new Date(timestamp).toISOString()
  const isoDate = isoDateTime.split('T')[0] + 'T00:00:00.000Z'
  return Date.parse(isoDate) / (86400 * 1000)
}

export default {
  name: 'FlightCard',
  components: {
    IconAirplaneDepart,
    IconAirplaneArrive,
    IconClock,
    AppDashedLine,
  },
  props: {
    flightDirection: Object,
  },
  computed: {
    segments() {
      const first = this.flightDirection.segments[0]
      const firstDepartureDateInDays = parseDays(first.departureDatetime)
      return this.flightDirection.segments.map(segment => {
        const currentDepartureDateInDays = parseDays(segment.departureDatetime)
        const currentArrivalDateInDays = parseDays(segment.arrivalDatetime)
        return {
          ...segment,
          departureDaysDiff:
            currentDepartureDateInDays - firstDepartureDateInDays,
          arrivalDaysDiff: currentArrivalDateInDays - firstDepartureDateInDays,
        }
      })
    },
    display() {
      const first = this.segments[0]
      return {
        airline: first.airline,
        flightCode: first.flightCode,
      }
    },
  },
  methods: {
    isFirst: index => index === 0,
    isLast(index) {
      return index === this.segments.length - 1
    },
    formatFlightDuration,
    formatFlightTime,
    formatCxnDuration(segmentIndex) {
      const departing = this.segments[segmentIndex]
      const arriving = this.segments[segmentIndex + 1]
      const start = departing.arrivalDatetime
      const end = arriving.departureDatetime
      return formatFlightDuration(end - start)
    },
  },
}
</script>

<style scoped lang="scss">
.flight-segment-details {
  display: grid;
  grid-template-columns: 50px 1fr;
  gap: 1rem;
  padding: 1rem;
  &-icons {
    display: grid;
    grid-template-rows: auto 1fr auto;
    justify-items: center;
    gap: 0.75rem;
    padding: 1rem 0rem;
  }
  &-content {
    display: grid;
    gap: 1rem;
  }
}
.flight-segment-details-time {
  font-family: LuciferSans;
  font-size: 20px;
}
.flight-segment-details-description {
  font-family: DDin;
  font-size: 14px;
}
.flight-segment-details-duration {
  font-family: DDin;
  font-size: 15px;
  display: flex;
  & > * + * {
    margin-left: 8px;
  }
  align-items: center;
}
</style>
